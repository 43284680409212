import React, { useState, useEffect } from "react"
import { Modal, Upload, Form, Select, Button } from "antd"
import { showSuccess, errorMessage } from "@action"
import { UploadOutlined } from "@ant-design/icons"
import { showSelectProps } from "@object"
import { AppraisalCycleClient } from "@api"

const UploadModal = ({
  visible,
  handleCancel,
  refresh,
  options = [],
  cycleId,
}) => {
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { Option } = Select

  const uploadData = async (file, degree360TemplateId) => {
    setIsLoading(true)
    try {
      const client = new AppraisalCycleClient()
      await client.upload(cycleId, degree360TemplateId, file)
      showSuccess("Upload " + file.filename + " Success")
      refresh()
      handleCancel()
    } catch (err) {
      errorMessage("Upload " + file.filename, err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!visible) {
      form.resetFields()
      setFileList([])
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const handleOnChange = ({ fileList }) => {
    setFileList(fileList)
  }
  const normFile = e => {
    let file = { data: e.file, filename: e.file.name }
    return file
  }
  const onFinish = values => {
    uploadData(values.file, values.degree360TemplateId)
  }
  return (
    <Modal
      forceRender
      title={"Upload Appraisal Target Excel"}
      destroyOnClose={true}
      confirmLoading={isLoading}
      visible={visible}
      okText="Upload"
      cancelText="Cancel"
      onCancel={() => {
        if (!isLoading) {
          handleCancel()
        }
      }}
      onOk={() => form.submit()}
    >
      <Form form={form} onFinish={onFinish} preserve={false}>
        <Form.Item label="Degree 360 Template" name="degree360TemplateId">
          <Select {...showSelectProps} allowClear>
            {options.map(option => (
              <Option key={option.id} value={option.id}>
                {option.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          valuePropName="file"
          name="file"
          label="File"
          getValueFromEvent={normFile}
          rules={[{ required: true, message: "Please upload a file" }]}
        >
          <Upload onChange={handleOnChange} beforeUpload={() => false}>
            {fileList.length < 1 && (
              <Button icon={<UploadOutlined />}>Select a File</Button>
            )}
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default UploadModal
