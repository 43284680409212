import React, { useState, useEffect } from "react"
import { Form, Select, DatePicker, Input } from "antd"
import { AppraisalCycleClient } from "@api"
import { showSuccess, errorMessage } from "@action"
import FormModal from "@components/FormModal"
import locale from "antd/es/date-picker/locale/en_US"
import moment from "moment"
import { showSelectProps } from "@object"

const EditModal = ({ visible, onCancel, options, refresh, data }) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const { Option } = Select
  const { RangePicker } = DatePicker

  const onFinish = async values => {
    const submitValues = {
      ...values,
      cutOffDate: values.cutOffDate.toJSON(),
      appraisalStartDate: values.appraisalPeriod[0].utcOffset(0, true).toJSON(),
      appraisalEndDate: values.appraisalPeriod[1].utcOffset(0, true).toJSON(),
      goalStartDate: values.goalPeriod
        ? values.goalPeriod[0].utcOffset(0, true).toJSON()
        : null,
      goalEndDate: values.goalPeriod
        ? values.goalPeriod[1].utcOffset(0, true).toJSON()
        : null,
    }
    try {
      setIsLoading(true)
      const client = new AppraisalCycleClient()
      await client.edit(data.id, { id: data.id, ...submitValues })
      showSuccess("Edit Appraisal Cycle Success")
      refresh()
      onCancel()
    } catch (err) {
      errorMessage("Edit Appraisal Cycle", err)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        ...data,
        appraisalPeriod: [
          moment(data.appraisalStartDate),
          moment(data.appraisalEndDate),
        ],
        cutOffDate: moment(data.cutOffDate),
        goalPeriod: [moment(data.goalStartDate), moment(data.goalEndDate)],
      })
    }
  }, [visible])

  return (
    <FormModal
      isCreate={false}
      form={form}
      onCancel={onCancel}
      title="Appraisal Cycle"
      isLoading={isLoading}
      visible={visible}
    >
      <Form layout="vertical" preserve={false} onFinish={onFinish} form={form}>
        <Form.Item
          name="name"
          label="Cycle Name"
          rules={[{ required: true, message: "Please input a name" }]}
        >
          <Input placeholder="Input a Cycle Name" />
        </Form.Item>
        <Form.Item
          name="code"
          label="Cycle Code"
          rules={[{ required: true, message: "Please input a code" }]}
        >
          <Input placeholder="Input a Cycle Code" />
        </Form.Item>
        <Form.Item
          name="appraisalPeriod"
          label="Appraisal Period"
          rules={[{ required: true, message: "Please select a date range" }]}
        >
          <RangePicker format={process.env.DATE_FORMAT} locale={locale} />
        </Form.Item>
        <Form.Item
          name="cutOffDate"
          label="Cut off Date"
          rules={[{ required: true, message: "Please select a date" }]}
        >
          <DatePicker
            format={process.env.DATE_FORMAT}
            placeholder="Select a Date"
            locale={locale}
          />
        </Form.Item>

        <Form.Item name="attendanceRecordFileId" label="Attendance Record">
          <Select
            {...showSelectProps}
            allowClear
            placeholder="Select a Attendance Record"
          >
            {options.attendanceFile.map(item => (
              <Option value={item.id}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="siteKPISetId" label="Site KPI">
          <Select
            {...showSelectProps}
            allowClear
            placeholder="Select a Site KPI"
          >
            {options.siteKPI.map(item => (
              <Option value={item.id}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="goalPeriod"
          label="Goal Achievement Period"
          rules={[{ required: true, message: "Please select a date range" }]}
        >
          <RangePicker format={process.env.DATE_FORMAT} locale={locale} />
        </Form.Item>

        <Form.Item
          name="degree360SupervisorSetId"
          label="Degree 360 Supervisor"
        >
          <Select
            placeholder="Select a Degree 360 Supervisor"
            {...showSelectProps}
            allowClear
          >
            {options.degree360.map(item => (
              <Option value={item.id}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </FormModal>
  )
}

export default EditModal
