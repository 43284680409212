import React, { useState, useEffect } from "react"
import { FormModal } from "lib"
import { Form, Select, Input } from "antd"
import { showSelectProps } from "@object"
import { showError, showSuccess } from "@action"
import { AppraisalTargetSetClient } from "@api"

const TargetGroupModal = ({
  isCreate,
  data,
  visible,
  cycleId,
  handleCancel,
  refresh,
  options = [],
}) => {
  const { Option } = Select
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = async values => {
    try {
      setIsLoading(true)
      const client = new AppraisalTargetSetClient()
      if (isCreate) {
        await client.create({
          ...values,
          appraisalCycleId: cycleId,
        })
        showSuccess("Add Appraisal Target Set Success")
      } else {
        await client.edit(data.id, {
          id: data.id,
          ...values,
          appraisalCycleId: cycleId,
        })
        showSuccess("Edit Appraisal Target Set Success")
      }
      refresh()
      handleCancel()
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      console.log(errmsg)
      showError(errmsg.errors)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (visible && data) form.setFieldsValue(data)
  }, [visible])

  return (
    <FormModal
      onCancel={handleCancel}
      title="Target Group"
      visible={visible}
      form={form}
      isCreate={isCreate}
      isLoading={isLoading}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, whitespace: true }]}
        >
          <Input placeholder="Please input a name" />
        </Form.Item>

        <Form.Item
          label="Appraisal Template"
          name="appraisalTemplateId"
          rules={[{ required: true }]}
        >
          <Select
            {...showSelectProps}
            placeholder="Select an Appraisal Template"
          >
            {options.map(option => (
              <Option id={option.id} value={option.id}>
                {`${option.id}. ${option.name}`}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </FormModal>
  )
}

export default TargetGroupModal
