import React, { useState, useEffect, useCallback } from "react"
import Seo from "@components/seo"
import MainLayout from "@components/main_layout"
import { PageHeader, Button, message, Descriptions, Space, Tag } from "antd"
import {
  AppraisalCycleClient,
  Degree360SupervisorSetClient,
  SiteKPISetClient,
  AttendanceRecordFileClient,
  AppraisalClient,
} from "@api"
import { navigate } from "gatsby"
import { EditModal, TargetGroupList } from "@components/configuartion/Cycle"
import { transformDate, showError, errorMessage } from "@action"
import "@style/appraisalCycle.less"
import { ConfirmButton } from "lib"

const CycleConfiguration = ({ location }) => {
  const { state = {} } = location
  const [isLoading, setIsLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [record, setRecord] = useState({})
  const [options, setOptions] = useState({
    targets: [],
    attendanceFile: [],
    siteKPI: [],
    degree360: [],
    templates: [],
  })

  const getData = useCallback(() => {
    const callAPI = async () => {
      try {
        const client = new AppraisalCycleClient()
        const result = await client.get2(state?.id)
        setRecord(result)
      } catch (err) {
        message.error("System Error occur")
        navigate("/")
      }
    }

    callAPI()
  }, [state?.id])

  const handleOnCancel = () => {
    setVisible(false)
  }

  const handleCycle = async () => {
    try {
      setIsLoading(true)
      const client = new AppraisalCycleClient()
      await client.start({ id: state?.id })
      getData()
    } catch (err) {
      errorMessage("Start Cycle", err)
    } finally {
      setIsLoading(false)
    }
  }

  const validateCycle = async () => {
    try {
      setIsLoading(true)
      const client = new AppraisalCycleClient()
      await client.validate(state?.id)
      message.success("Validation Success")
    } catch (err) {
      errorMessage("Validation", err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleUpdate = async () => {
    try {
      setIsLoading(true)
      const client = new AppraisalClient()
      await client.updateAppraisalScore({
        appraisalCycleId: state?.id,
        hasSiteKPI: true,
      })
      message.success("Update SiteKPI Success")
    } catch (err) {
      showError(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [getData])

  useEffect(() => {
    const getOptions = async () => {
      const degree360client = new Degree360SupervisorSetClient()
      const degree360Res = await degree360client
        .get(0, 0, "id", true)
        .catch(err => {
          console.log(err)
          return {
            content: {
              degree360SupervisorSetGroup: [],
            },
          }
        })

      const degree360 = degree360Res.content.degree360SupervisorSetGroup.filter(
        item => item.isEnabled
      )

      const siteKPIClient = new SiteKPISetClient()
      const siteRes = await siteKPIClient.get(0, 0).catch(err => {
        console.log(err)
        return {
          content: {
            siteKPISetGroup: [],
          },
        }
      })

      const attendanceClient = new AttendanceRecordFileClient()
      const attendanceRes = await attendanceClient
        .get(true, "id", true, 0, 0)
        .catch(err => {
          console.log(err)
          return {
            list: [],
          }
        })

      setOptions({
        attendanceFile: attendanceRes.list,
        siteKPI: siteRes.content.siteKPISetGroup,
        degree360: degree360,
      })
    }

    getOptions()
  }, [])

  return (
    <MainLayout>
      <Seo title="Appraisal Cycle configuration" />
      <PageHeader
        onBack={() => window.history.back()}
        title={record?.name}
        ghost={false}
        extra={
          !record.startDate ? (
            <Space>
              <Button
                type="primary"
                ghost
                loading={isLoading}
                onClick={() => {
                  validateCycle()
                }}
              >
                Validate
              </Button>
              <ConfirmButton
                title="Confirm to Start Appraisal ?"
                type="primary"
                okText="Start"
                loading={isLoading}
                onClick={() => {
                  handleCycle()
                }}
              >
                Start Cycle
              </ConfirmButton>
              <Button
                loading={isLoading}
                onClick={() => {
                  setVisible(true)
                }}
              >
                Edit
              </Button>
            </Space>
          ) : (
            <Space>
              <Tag color="green">
                Started - {transformDate(record?.startDate)}
              </Tag>
              {record.siteKPISetId && (
                <ConfirmButton
                  size="small"
                  type="ghost"
                  danger
                  title="Confirm to update KPI score ?"
                  loading={isLoading}
                  onClick={() => {
                    handleUpdate()
                  }}
                >
                  Update Score
                </ConfirmButton>
              )}
            </Space>
          )
        }
      >
        <Descriptions className="person-descriptions" layout="vertical">
          <Descriptions.Item label="Cycle Name">
            {record.name}
          </Descriptions.Item>
          <Descriptions.Item label="Cycle Code">
            {record.code}
          </Descriptions.Item>
          <Descriptions.Item label="Appraisal Period">
            {transformDate(record.appraisalStartDate) +
              " to " +
              transformDate(record.appraisalEndDate)}
          </Descriptions.Item>
          <Descriptions.Item label="Cut off Date">
            {transformDate(record.cutOffDate)}
          </Descriptions.Item>
          <Descriptions.Item label="Attendance Record">
            {record.attendanceRecordFileName}
          </Descriptions.Item>
          <Descriptions.Item label="Site KPI">
            {record.siteKPISetName}
          </Descriptions.Item>
          <Descriptions.Item label="Goal Achievement Period">
            {`${transformDate(record.goalStartDate) ?? "-"} to ${
              transformDate(record.goalEndDate) ?? "-"
            }`}
          </Descriptions.Item>
          <Descriptions.Item label="Degree 360 Supervisor">
            {record.degree360SupervisorSetName}
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>
      <EditModal
        options={options}
        visible={visible}
        onCancel={handleOnCancel}
        data={record}
        refresh={() => getData()}
      />

      <TargetGroupList id={state?.id} isStart={!!record.startDate} />
    </MainLayout>
  )
}

export default CycleConfiguration
