import React, { useReducer, useCallback, useEffect } from "react"
import { Tabs, Button, Space, Input } from "antd"
import { PanelTable, EditButton, ViewButton, DeleteButton } from "lib"
import TargetGroupModal from "./TargetGroupModal"
import { PlusOutlined, UploadOutlined } from "@ant-design/icons"
import { AppraisalTemplateClient, AppraisalTargetSetClient } from "@api"
import { navigate, Link } from "gatsby"
import { showError } from "@action"
import UploadModal from "./UploadModal"

const TargetGroupList = ({ id, isStart }) => {
  const { TabPane } = Tabs
  const initialState = {
    options: { degree360Template: [], template: [] },
    dataSource: [],
    searchParams: {
      staffNo: null,
      pageSize: 10,
      pageIndex: 0,
      orderBy: "Name",
      isAscend: true,
    },
    pageMeta: {
      currentPage: 1,
      totalCount: 10,
      pageSize: 10,
    },
    isLoading: false,
    modal: {
      isCreate: false,
      data: null,
      visible: false,
      id: null,
    },
    uploadModal: {
      visible: false,
    },
  }

  const reducer = (state, { type, payload }) => {
    switch (type) {
      case "search":
        return {
          ...state,
          searchParams: {
            ...initialState.searchParams,
            staffNo: payload,
          },
        }

      case "isLoading":
        return {
          ...state,
          isLoading: payload,
        }
      case "upload":
        return {
          ...state,
          uploadModal: {
            visible: true,
          },
        }

      case "create":
        return {
          ...state,
          modal: {
            isCreate: true,
            data: null,
            visible: true,
            cycleId: id,
          },
        }
      case "edit":
        return {
          ...state,
          modal: {
            isCreate: false,
            data: payload,
            visible: true,
            cycleId: id,
          },
        }
      case "pageChange":
        return {
          ...state,
          searchParams: {
            ...state.searchParams,
            ...payload,
          },
        }
      case "loadData":
        return {
          ...state,
          dataSource: payload.items,
          pageMeta: {
            currentPage: payload.pageIndex,
            totalCount: payload.totalCount,
            pageSize: payload.pageSize,
          },
        }
      case "close":
        return {
          ...state,
          modal: initialState.modal,
          uploadModal: initialState.uploadModal,
        }

      case "options":
        return {
          ...state,
          options: payload,
        }
      case "reset":
        return {
          ...state,
          searchParams: initialState.searchParams,
          isLoading: false,
        }

      default:
        break
    }
  }
  const [
    {
      dataSource,
      pageMeta,
      isLoading,
      modal,
      searchParams,
      options,
      uploadModal,
    },
    dispatch,
  ] = useReducer(reducer, initialState)

  const handleDelete = async id => {
    try {
      dispatch({ type: "isLoading", payload: true })
      const client = new AppraisalTargetSetClient()
      await client.delete(id)
      dispatch({ type: "reset" })
    } catch (err) {
      showError(err)
      dispatch({ type: "isLoading", payload: false })
    }
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Template",
      dataIndex: "appraisalTemplateName",
      render: (text, { appraisalTemplateId }) => (
        <Link
          to="/configuration/viewTemplate"
          state={{ id: appraisalTemplateId }}
        >{`${appraisalTemplateId}. ${text}`}</Link>
      ),
    },
    {
      title: "Targets Count",
      dataIndex: "appraisalTargetsCount",
      align: "right",
    },
    {
      title: "Action",
      render: (_, record) => (
        <Space>
          <ViewButton
            onClick={() => {
              navigate("/configuration/target/", {
                state: { id: record.id, isStart: isStart },
              })
            }}
          />
          {!isStart && (
            <EditButton
              onClick={() => {
                dispatch({ type: "edit", payload: record })
              }}
            />
          )}
          {record.isCanDelete && (
            <DeleteButton
              onClick={() => {
                handleDelete(record.id)
              }}
            />
          )}
        </Space>
      ),
    },
  ]

  const fetchData = useCallback(() => {
    const callApi = async params => {
      const client = new AppraisalTargetSetClient()
      const result = await client
        .get(
          params.pageSize,
          params.pageIndex + 1,
          params.orderBy,
          params.isAscend,
          id,
          params.staffNo
        )
        .catch(err => {
          console.log(err)
          return {
            items: [],
          }
        })
      dispatch({ type: "loadData", payload: result })
    }

    if (id) callApi(searchParams)
  }, [searchParams])

  const onSearch = value => {
    dispatch({ type: "search", payload: value })
  }

  useEffect(() => {
    const fetchOptions = async () => {
      const templateClient = new AppraisalTemplateClient()
      const templateRes = await templateClient
        .getOptions(true, false, "name", true)
        .catch(err => {
          console.log(err)
          return []
        })

      const degree360Res = await templateClient
        .getOptions(true, true, "name", true)
        .catch(err => {
          console.log(err)
          return []
        })

      dispatch({
        type: "options",
        payload: {
          template: templateRes,
          degree360Template: degree360Res,
        },
      })
    }

    fetchOptions()
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <Tabs
      type="card"
      tabBarExtraContent={
        <Input.Search
          enterButton={true}
          placeholder="Search with Staff Number"
          loading={isLoading}
          onSearch={onSearch}
          allowClear={true}
        />
      }
    >
      <TabPane tab="Appraisal Target Group">
        <Space style={{ margin: "10px 0px" }}>
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            onClick={() => {
              dispatch({ type: "create" })
            }}
          >
            Add
          </Button>
          <Button
            shape="round"
            icon={<UploadOutlined />}
            onClick={() => {
              dispatch({ type: "upload" })
            }}
          >
            Upload
          </Button>
        </Space>

        <PanelTable
          dataSource={dataSource}
          columns={columns}
          pageMeta={pageMeta}
          isLoading={isLoading}
          setSearchParams={values =>
            dispatch({ type: "pageChange", payload: values })
          }
          defaultOrder={{
            orderBy: "id",
            isAscend: false,
          }}
        />
        <TargetGroupModal
          {...modal}
          option
          handleCancel={() => {
            dispatch({ type: "close" })
          }}
          refresh={fetchData}
          options={options.template}
        />
        <UploadModal
          {...uploadModal}
          handleCancel={() => {
            dispatch({ type: "close" })
          }}
          refresh={fetchData}
          options={options.degree360Template}
          cycleId={id}
        />
      </TabPane>
    </Tabs>
  )
}

export default TargetGroupList
